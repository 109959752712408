<template>
  <div class="card" :class="dark ? 'bg-gradient-dark' : ''">
    <div class="pb-0 card-header" :class="dark ? 'bg-transparent' : ''">
      <h6 :class="dark ? 'text-white' : ''">{{ title }}</h6>
      <!-- <h4>{{ lastTrackingList.status }}</h4>
      <p>{{ lastTrackingList.shipper_order_ref_no }}<br /><small><span v-if="lastTrackingList.comments != null">{{ lastTrackingList.comments }}</span><span class="text-secondary">{{ lastTrackingList.timestamp }}</span></small></p>
      <p v-show=false>{{ lastTrackingList }}</p> -->
      <!--  eslint-disable-next-line vue/no-v-html -->
      <p v-if="description" class="text-sm" v-html="description"></p>
    </div>
    <div class="p-3 card-body">
      <div
        class="timeline timeline-one-side"
        :data-timeline-axis-style="dark ? 'dashed' : 'dotted'"
      >
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimelineList",
  props: {
    title: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    lastTrackingList: {
      type: Object,
      required: true,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
